@import 'variables';

.top-bar__icon {
	width: 40px;
	stroke-dasharray: 10;
	animation-iteration-count: infinite;
	animation: ckw 10s ease forwards infinite;
	stroke: $neutral-black;

	path {
		stroke: $neutral-black;
	}
}

path {
	stroke: $neutral-black;
}

@keyframes ckw {
	to {
		stroke-dashoffset: 100;
	}
}
