@import '@fontsource/dm-sans';

// colors
$base-white: #ffffff;
$beige-neutral: #e5e1de;

$strong-blue: #1d3d53;
$faded-cadet: RGBA(94, 137, 165, 0.5);
$neutral-black: #181c1f;
$chill-blue: #5e89a5;
$grey: rgba(172, 172, 172, 0.87);
$transparent: RGBA(0, 0, 0, 0);

$teal: #2a9d8f;
$green: #264653;
$yellow: #e9c46a;
$orange: #f4a261;
$sienna: #e76f51;

// font
$base-font: 'DM Sans', sans-serif;
// font - weight
$w300: 300;
$w400: 400;
$w500: 500;
$w700: 700;
// font - size
$font-20: 20px;
$font-30: 30px;

// spaces
$spacing-6: 6px;
$spacing-10: 10px;
$spacing-15: 15px;
$spacing-20: 20px;
$spacing-25: 25px;
$spacing-30: 30px;
$spacing-40: 40px;
$spacing-70: 70px;
$spacing-80: 80px;
$spacing-120: 120px;
$spacing-160: 160px;

$gap-100: 100px;
